import 'vite/modulepreload-polyfill';

import type { WebOrderMeta } from '#widget/entities/web-order-meta';
import type { TPopupType } from '#widget/support/types';

import { attributionService } from '#widget/services/attribution/attribution.service';
import { kyBioBackendService } from '#widget/services/kybio-backend/kybio-backend.service';
import { kyBioWebClientService } from '#widget/services/kybio-web-client/kybio-web-client.service';
import { withSentryScope } from '#widget/support/helpers/with-sentry-scope';

withSentryScope((scope) => {
  let bootstrapped = false;

  scope.setTag('component', 'kybio-crm-widget');

  const bootstrap = () => {
    if (bootstrapped) {
      return;
    }

    const [firstTouchParams, lastTouchParams] = attributionService.track();

    console.log('[widget] visit attributed', { firstTouchParams, lastTouchParams });

    kyBioWebClientService.onPopupReady('cart', () => {
      console.log('[widget] cart popup ready');

      kyBioWebClientService.setPopupMarketingFields('cart', firstTouchParams);

      console.log('[widget] cart popup form fields modified');

      kyBioWebClientService.onPopupFormSubmit('cart', async () => {
        console.log('[widget] cart popup submitted');

        const webOrder: WebOrderMeta = {
          type: 'cart',
          date: new Date(),
          total: kyBioWebClientService.parseCartPopupOrderTotal(),
          customerName: kyBioWebClientService.getPopupFormValue('cart', 'customerName'),
          customerPhone: kyBioWebClientService.getPopupFormValue('cart', 'customerPhone'),
          customerEmail: kyBioWebClientService.getPopupFormValue('cart', 'customerEmail'),
          firstTouchData: firstTouchParams,
          lastTouchData: lastTouchParams,
        };

        const success = await kyBioBackendService.trackWebOrder(webOrder);

        if (success) {
          console.log('[widget] cart popup order tracked', { meta: webOrder });
        } else {
          console.error('[widget] cart popup order was not tracked', { meta: webOrder });
        }
      });
    });

    const directPopups: TPopupType[] = [
      'direct-order',
      'direct-instructions',
      'direct-recommendations',
    ];

    for (const popupType of directPopups) {
      kyBioWebClientService.onPopupReady(popupType, () => {
        console.log(`[widget] ${popupType} popup ready`);

        kyBioWebClientService.setPopupMarketingFields(popupType, firstTouchParams);

        console.log(`[widget] ${popupType} popup form fields modified`);

        kyBioWebClientService.onPopupFormSubmit(popupType, async () => {
          console.log(`[widget] ${popupType} popup submitted`);

          const webOrder: WebOrderMeta = {
            type: popupType,
            date: new Date(),
            customerName: kyBioWebClientService.getPopupFormValue(popupType, 'customerName'),
            customerPhone: kyBioWebClientService.getPopupFormValue(popupType, 'customerPhone'),
            firstTouchData: firstTouchParams,
            lastTouchData: lastTouchParams,
          };

          const success = await kyBioBackendService.trackWebOrder(webOrder);

          if (success) {
            console.log(`[widget] ${popupType} popup order tracked`, { meta: webOrder });
          } else {
            console.error(`[widget] ${popupType} popup order was not tracked`, { meta: webOrder });
          }
        });
      });
    }

    console.log('[widget] widget has been bootstrapped');
    bootstrapped = true;
  };

  void bootstrap();
  document.addEventListener('DOMContentLoaded', bootstrap);
});
