import { z } from 'zod';

// @see: define prop in vite.config.ts
const EnvConfigSchema = z.object({
  BACKEND_BASE_URL: z.string().url(),
  SENTRY_DSN: z.string().url(),
});

export const envConfig = () => {
  return EnvConfigSchema.parse({
    BACKEND_BASE_URL: import.meta.env.VITE_BACKEND_BASE_URL,
    SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  });
};

declare global {
  interface ImportMetaEnv {
    VITE_BACKEND_BASE_URL?: string | undefined;
    VITE_SENTRY_DSN?: string | undefined;
  }
}
