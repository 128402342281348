import * as Sentry from '@sentry/browser';

import { envConfig } from '#widget/support/helpers/env-config';

const config = envConfig();

Sentry.init({
  debug: false,
  enabled: true,
  dsn: config.SENTRY_DSN,
  sendDefaultPii: true,
  integrations: [
    Sentry.browserTracingIntegration({
      enableInp: true,
      enableHTTPTimings: true,
      enableLongTask: true,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.captureConsoleIntegration(),
    Sentry.zodErrorsIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.httpContextIntegration(),
  ],

  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/kybio\.com\.ua/],

  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
});

export const withSentryScope = Sentry.withScope;
