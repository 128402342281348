import type { ValueLike } from '#support/helpers/value_of';

import { valueOf } from '#support/helpers/value_of';

export class InvariantViolationError extends Error {
  public override readonly name = 'InvariantError';
}

export const invariant: (condition: boolean, message: ValueLike<string>) => asserts condition = (
  condition,
  message: ValueLike<string>,
) => {
  if (!condition) {
    const error = new InvariantViolationError(`[InvariantViolation]: ${valueOf(message)}`);

    // Capture the stack trace, excluding the invariant function itself
    Error.captureStackTrace(error, invariant);

    throw error;
  }
};
