import { invariant } from '#support/helpers/invariant';

export type TapCallback<V, R> = (value: V) => R;
export type TapCallbackNoArg<R> = () => R;

export function tap<R>(callback: TapCallbackNoArg<R>): R;
export function tap<V, R>(value: V, callback: TapCallback<V, R>): R;
export function tap<V, R>(
  valueOrCallback: V | TapCallbackNoArg<R>,
  callback?: TapCallback<V, R>,
): R {
  if (typeof valueOrCallback === 'function') {
    return (valueOrCallback as TapCallbackNoArg<R>)();
  }

  invariant(callback !== undefined, 'callback is required when argument is supplied');
  return callback(valueOrCallback);
}
