export type ValueLike<T> = T | (() => T);
export type ValueOfCallback<R> = () => R;

export const valueOf = <T>(valueOrCallback: T | ValueOfCallback<T>): T => {
  if (typeof valueOrCallback === 'function') {
    return (valueOrCallback as ValueOfCallback<T>)();
  }

  return valueOrCallback;
};
