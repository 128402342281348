import type { CreateWebOrderOrderData } from '#validators/create_web_order';
import type { WebOrderMeta } from '#widget/entities/web-order-meta';

import { StatusCodes } from 'http-status-codes';

import { envConfig } from '#widget/support/helpers/env-config';

class KybioBackendService {
  public async trackWebOrder(webOrder: WebOrderMeta) {
    const { BACKEND_BASE_URL } = envConfig();

    const data: CreateWebOrderOrderData = {
      type: webOrder.type,
      total: webOrder.total,
      customerName: webOrder.customerName,
      customerPhone: webOrder.customerPhone,
      customerEmail: webOrder.customerEmail,
      firstTouch: {
        source: webOrder.firstTouchData.source,
        utmSource: webOrder.firstTouchData.utm_source,
        utmMedium: webOrder.firstTouchData.utm_medium,
        utmCampaign: webOrder.firstTouchData.utm_campaign,
        utmContent: webOrder.firstTouchData.utm_content,
        utmId: webOrder.firstTouchData.utm_id,
        utmTerm: webOrder.firstTouchData.utm_term,
        clickId: webOrder.firstTouchData.click_id,
      },
      lastTouch: {
        source: webOrder.lastTouchData.source,
        utmSource: webOrder.lastTouchData.utm_source,
        utmMedium: webOrder.lastTouchData.utm_medium,
        utmCampaign: webOrder.lastTouchData.utm_campaign,
        utmContent: webOrder.lastTouchData.utm_content,
        utmId: webOrder.lastTouchData.utm_id,
        utmTerm: webOrder.lastTouchData.utm_term,
        clickId: webOrder.lastTouchData.click_id,
      },
    };

    const response = await fetch(`${BACKEND_BASE_URL}/api/v1/widget/order/track`, {
      method: 'POST',
      keepalive: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    return response.ok && response.status === StatusCodes.CREATED;
  }
}

export const kyBioBackendService = new KybioBackendService();
