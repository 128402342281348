import type { TMarketingParams } from '#widget/services/attribution/attribution.service';
import type { TPopupType } from '#widget/support/types';

type FieldDefinition = Record<
  string,
  {
    label: string | undefined;
    placeholder: string;
  }
>;

type PopupDefinition = Record<string, string>;

export class KYBioWebClientService {
  private readonly popups: Record<TPopupType, PopupDefinition> = {
    'cart': {
      popup: '.popup.js-shopping-cart.popup-active',
      submit: 'a.js-order-button[data-component="button"]',
      orderTotal:
        '[data-cart-component="order-details"] [data-sub-item="price-details"] [data-sub-item="total"]',
    },
    'direct-order': {
      popup: '.popup.popup-active[data-popup-anchor="5dd28db5f6846c0021674552"]',
      submit: 'form.w-form button.w-form__button[type="submit"]',
    },
    'direct-instructions': {
      popup: '.popup.popup-active[data-popup-anchor="62daa8fce8fbc2000d7a063a"]',
      submit: 'form.w-form button.w-form__button[type="submit"]',
    },
    'direct-recommendations': {
      popup: '.popup.popup-active[data-popup-anchor="62dabaefddf442000e7f14be"]',
      submit: 'form.w-form button.w-form__button[type="submit"]',
    },
  };

  private readonly formFields: Record<TPopupType, FieldDefinition> = {
    'cart': {
      customerName: {
        label: `ПІП`,
        placeholder: `Ваше ім'я`,
      },
      customerPhone: {
        label: `Номер телефону`,
        placeholder: `+38 (___) ___ - __ - __`,
      },
      customerEmail: {
        label: `Email (якщо є)`,
        placeholder: `Email`,
      },
    },
    'direct-order': {
      customerName: {
        label: undefined,
        placeholder: `Ваше ім’я`,
      },
      customerPhone: {
        label: undefined,
        placeholder: `+38 (___) ___ - __ - __`,
      },
    },
    'direct-instructions': {
      customerName: {
        label: undefined,
        placeholder: `Ваше ім’я`,
      },
      customerPhone: {
        label: undefined,
        placeholder: `+38 (___) ___ - __ - __`,
      },
    },
    'direct-recommendations': {
      customerName: {
        label: undefined,
        placeholder: `Ваше ім’я`,
      },
      customerPhone: {
        label: undefined,
        placeholder: `+38 (___) ___ - __ - __`,
      },
    },
  };

  private popupStates = new Map<string, boolean>();

  private popupIntervals = new Map<string, ReturnType<typeof setInterval>>();

  public onPopupReady(popup: TPopupType, callback: (element: HTMLElement) => void): void {
    this.onSelectorReady(this.popups[popup].popup, callback);
  }

  public onPopupFormSubmit(popup: TPopupType, callback: () => void | Promise<void>): void {
    const container = document.querySelector<HTMLElement>(this.popups[popup].popup);

    if (container) {
      const handleSubmit = () => {
        const invalidClasses = [
          ...new Set(
            [...container.querySelectorAll('[data-invalid-classname]')].map((element) => {
              return `.${element.getAttribute('data-invalid-classname')}`;
            }),
          ),
        ];

        const invalidElements = container.querySelectorAll(invalidClasses.join(','));

        if (!invalidElements || invalidElements.length === 0) {
          void callback();
        }
      };

      container.querySelector(this.popups[popup].submit)?.addEventListener('click', () => {
        setTimeout(handleSubmit, 100);
      });
    }
  }

  public getPopupFormValue<P extends TPopupType, F extends keyof (typeof this.formFields)[P]>(
    popup: P,
    field: F,
  ): string | undefined {
    const container = document.querySelector<HTMLElement>(this.popups[popup].popup);

    if (container) {
      const input =
        this.findFormFieldByLabel<HTMLInputElement>(
          container,
          this.formFields[popup][field].label,
        ) ??
        this.findFormFieldByPlaceholder<HTMLInputElement>(
          container,
          this.formFields[popup][field].placeholder,
        );

      if (input && input.value.trim() !== '') {
        return input.value;
      }

      return undefined;
    }

    return undefined;
  }

  public parseCartPopupOrderTotal(): number | undefined {
    const container = document.querySelector<HTMLElement>(this.popups.cart.popup);

    if (container) {
      const priceElement = container.querySelector(this.popups.cart.orderTotal);

      if (!priceElement?.textContent) {
        return undefined;
      }

      const priceCleaned = priceElement.textContent
        .trim()
        .replace(/[^\d,\s.]/g, '')
        .replace(',', '.')
        .replace(/\s/g, '');

      const price = parseFloat(priceCleaned);

      if (Number.isNaN(price)) {
        return undefined;
      }

      return price;
    }

    return undefined;
  }

  public setPopupMarketingFields(popup: TPopupType, params: TMarketingParams) {
    const container = document.querySelector<HTMLElement>(this.popups[popup].popup);

    if (!container) {
      throw new Error('Could not set marketing fields. Cart popup not found in the DOM.');
    }

    const marketingFields: [string, keyof TMarketingParams][] = [
      ['ft_source', 'source'],
      ['ft_utm_source', 'utm_source'],
      ['ft_utm_medium', 'utm_medium'],
      ['ft_utm_campaign', 'utm_campaign'],
      ['ft_utm_content', 'utm_content'],
      ['ft_utm_id', 'utm_id'],
      ['ft_utm_term', 'utm_term'],
      ['ft_click_id', 'click_id'],
    ];

    for (const marketingField of marketingFields) {
      const [label, tag] = marketingField;

      const section = this.findFormFieldByLabel<HTMLElement>(container, label, 'wrapper');

      if (section) {
        section.style.display = 'none';

        const input = section.querySelector('input');

        if (input) {
          input.value = params[tag] ?? '';
        }
      }
    }
  }

  private findFormFieldByLabel<E extends Element = Element>(
    container: HTMLElement,
    label: string | undefined,
    target: 'input' | 'wrapper' = 'input',
  ): E | undefined {
    if (!label) {
      return undefined;
    }

    const sections = container.querySelectorAll<E>('.w-form__field');

    for (const section of sections) {
      const fieldName = section.querySelector('span[data-sub-item="name"]');

      if (fieldName?.textContent?.toString().trim().includes(label)) {
        const input = section.querySelector('input[name][data-sub-item]');

        if (target === 'input' && input) {
          return input as E;
        }

        if (target === 'wrapper') {
          return section;
        }
      }
    }

    return undefined;
  }

  private findFormFieldByPlaceholder<E extends Element = Element>(
    container: HTMLElement,
    placeholder: string | undefined,
    target: 'input' | 'wrapper' = 'input',
  ): E | undefined {
    if (!placeholder) {
      return undefined;
    }

    const sections = container.querySelectorAll<E>('.w-form__field');

    for (const section of sections) {
      const input = section.querySelector('input[name][data-sub-item][placeholder]');

      if (
        target === 'input' &&
        input?.attributes?.getNamedItem('placeholder')?.value.includes(placeholder)
      ) {
        return input as E;
      }

      if (target === 'wrapper') {
        return section;
      }
    }

    return undefined;
  }

  private onSelectorReady(selector: string, callback: (element: HTMLElement) => void): void {
    if (this.popupIntervals.has(selector)) {
      clearInterval(this.popupIntervals.get(selector));
    }

    const isVisible = (element: HTMLElement): boolean => {
      return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
    };

    const interval = setInterval(() => {
      const element = document.querySelector<HTMLElement>(selector);

      const wasVisible = this.popupStates.get(selector) ?? false;
      const isVisibleNow = element !== null && isVisible(element);

      if (!wasVisible && isVisibleNow) {
        callback(element);
        this.popupStates.set(selector, true);
      } else if (wasVisible && !isVisibleNow) {
        this.popupStates.set(selector, false);
      }
    }, 250);

    this.popupIntervals.set(selector, interval);
  }
}

export const kyBioWebClientService = new KYBioWebClientService();
