import cookies from 'js-cookie';
import { z } from 'zod';

import { tap } from '#support/helpers/tap';

export const RequestClickIdParamsSchema = z.object({
  gclid: z.string().optional(),
  wbraid: z.string().optional(),
  gbraid: z.string().optional(),
  ad_id: z.string().optional(),
  gc_id: z.string().optional(),
  fbclid: z.string().optional(),
  msclkid: z.string().optional(),
  twclid: z.string().optional(),
  li_fat_id: z.string().optional(),
  e_cp: z.string().optional(),
  ttclid: z.string().optional(),
  sc_cp: z.string().optional(),
  ymid: z.string().optional(),
  ob_click_id: z.string().optional(),
  tblci: z.string().optional(),
  mc_cid: z.string().optional(),
  mc_eid: z.string().optional(),
});

export const RequestParamsSchema = z.object({
  utm_source: z.string().optional(),
  utm_medium: z.string().optional(),
  utm_campaign: z.string().optional(),
  utm_content: z.string().optional(),
  utm_id: z.string().optional(),
  utm_term: z.string().optional(),
  referrer: z.string().optional(),
});

export const MarketingParamsSchema = z.object({
  source: z.string(),
  utm_source: z.string().optional(),
  utm_medium: z.string().optional(),
  utm_campaign: z.string().optional(),
  utm_content: z.string().optional(),
  utm_id: z.string().optional(),
  utm_term: z.string().optional(),
  click_id: z.string().optional(),
});

export type TRequestParams = z.infer<typeof RequestParamsSchema>;
export type TRequestClickIdParams = z.infer<typeof RequestClickIdParamsSchema>;
export type TMarketingParams = z.infer<typeof MarketingParamsSchema>;

export class AttributionService {
  private readonly schemaVersion = 1_2;

  private readonly cookieNames = {
    firstTouch: `__first_touch__${this.schemaVersion}`,
  };

  private readonly utmParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_id',
    'utm_term',
  ];

  private readonly clickParamsMap = {
    'Google Ads': ['gclid', 'wbraid', 'gbraid', 'ad_id', 'gc_id'],
    'Meta Ads': ['fbclid'],
    'Microsoft Ads': ['msclkid'],
    'Twitter Ads': ['twclid'],
    'LinkedIn Ads': ['li_fat_id'],
    'Pinterest Ads': ['e_cp'],
    'TikTok Ads': ['ttclid'],
    'Snapchat Ads': ['sc_cp'],
    'Yahoo Ads': ['ymid'],
    'Outbrain Ads': ['ob_click_id'],
    'Taboola Ads': ['tblci'],
    'Mailchimp': ['mc_cid', 'mc_eid'],
  };

  public track(): [TMarketingParams, TMarketingParams] {
    const lastTouchParams = this.parseMarketingParams();

    let firstTouchParams = this.load('firstTouch');

    if (!firstTouchParams) {
      if (lastTouchParams.source !== 'Other') {
        this.save('firstTouch', lastTouchParams);
      }

      firstTouchParams = lastTouchParams;
    }

    return [firstTouchParams, lastTouchParams] as const;
  }

  private parseMarketingParams(): TMarketingParams {
    const query = new URLSearchParams(window.location.search);

    const params: TRequestParams & TRequestClickIdParams = {};

    [...this.utmParams, ...Object.values(this.clickParamsMap).flat()].forEach((param) => {
      const value = query.get(param);

      if (!(value === undefined || value === null || value.trim() === '')) {
        params[param as keyof (TRequestParams & TRequestClickIdParams)] = value;
      }
    });

    const { referrer } = window.document;

    if (referrer && referrer !== '' && referrer !== window.location.href) {
      params.referrer = referrer;
    }

    return {
      source: tap(() => {
        for (const [adNetwork, clickIdParams] of Object.entries(this.clickParamsMap)) {
          if (clickIdParams.some((param) => Object.prototype.hasOwnProperty.call(params, param))) {
            return adNetwork;
          }
        }

        if (params.utm_source && ['fb', 'ig'].includes(params.utm_source.toLowerCase())) {
          return 'Meta Ads';
        }

        if (
          Object.keys(params).some((key) => Object.values(this.clickParamsMap).flat().includes(key))
        ) {
          return 'Paid Ads';
        }

        return 'Other';
      }),
      utm_source: tap(() => {
        return params.utm_source;
      }),
      utm_medium: tap(() => {
        return params.utm_medium;
      }),
      utm_campaign: tap(() => {
        return params.utm_campaign;
      }),
      utm_content: tap(() => {
        return params.utm_content;
      }),
      utm_id: tap(() => {
        return params.utm_id;
      }),
      utm_term: tap(() => {
        return params.utm_term;
      }),
      click_id: tap(() => {
        for (const param of Object.values(this.clickParamsMap).flat()) {
          if (Object.prototype.hasOwnProperty.call(params, param)) {
            return `${param}=${params[param as keyof TRequestClickIdParams]}`;
          }
        }

        return undefined;
      }),
    };
  }

  private load(name: keyof typeof this.cookieNames): TMarketingParams | undefined {
    try {
      const data = cookies.get(this.cookieNames[name]);

      if (!data) {
        return undefined;
      }

      return MarketingParamsSchema.parse(JSON.parse(data));
    } catch {
      return undefined;
    }
  }

  private save(name: keyof typeof this.cookieNames, params: Record<string, unknown>): void {
    cookies.set(this.cookieNames[name], JSON.stringify(params), {
      expires: 365,
      sameSite: 'lax',
    });
  }
}

export const attributionService = new AttributionService();
